import React from 'react';

export default function MenuSection({menus}){
    const iterate = menus.items;
    console.log(iterate)
    return (
        <section className="mains">
            <h2 className="extras-heading">{menus.name}</h2>
            {iterate.map((menu, index) => (
                <article className="menu-item" key={index}>
                    <h3 className="mains-name">{menu.name}</h3>
                    <strong className="mains-price">${menu.price}</strong>
                    <p className="mains-description">{menu.description}</p>
                </article>
            ))}
        </section>
    );
}