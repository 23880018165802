import React from "react";
import './App.css';
import data from './data';
import MenuSection from './components/Menu';

export default function App(){
  return (
    <div className="menu">
      {/* <img src={`#`} style={{width:100,height:50}}/> */}
      <MenuSection menus={data.mains}/>
      <aside className="aside">
        <MenuSection menus={data.sides}/>
        <MenuSection menus={data.drinks}/>
          {/* <Extras type="Sides" items={sides} />
          <Extras type="Drinks" items={drinks} /> */}
        </aside>
    </div>
  )
}

// Old Code



// import logo from './logo.svg';
// import './App.css';
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import Root from "./routes/root";
// import ErrorPage from "./error-page";

// const router = createBrowserRouter([
//   { path: "*", element: <Root />, errorElement: <ErrorPage /> },
//   { path: "/app", Component: Root }
// ]);

// export default function App() {
//   return <RouterProvider router={router} />;
// }

// function Root() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           - a MoonProduct.in product
//         </p>
//         <a
//           className="App-link"
//           href="https://moonproduct.in"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Visit Company Site
//         </a>
//       </header>
//     </div>
//   );
// }

// const App = createBrowserRouter([
//   {
//     path: "/",
//     element: <div>Hello world!</div>,
//   },
// ]);

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <RouterProvider router={app} />
//   </React.StrictMode>
// );

// export default App;
